import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { Offer } from "interfaces/Offer";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import MyOfferCardItem from "./MyOfferCardItem";

import * as c from "./OfferCarousel.module.css";

interface OfferCarouselProps {
  openModal: (offer: Offer, type: "offer" | "reward") => void;
  detectOs: boolean;
  myOffers: Offer[];
}

const OfferCarousel: React.FC<OfferCarouselProps> = ({ openModal, detectOs, myOffers }) => {
  const [scrollPos, setScrollPos] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);
  const configuration = getCachedConfiguration();
  const trackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (trackRef.current) {
      setMaxScroll(trackRef.current.scrollWidth - trackRef.current.clientWidth);
    }
  }, [myOffers]);

  const handleScroll = () => {
    if (trackRef.current) {
      setScrollPos(trackRef.current.scrollLeft);
    }
  };

  const nextSlide = () => {
    if (trackRef.current) {
      trackRef.current.scrollBy({
        left: trackRef.current.clientWidth / (detectOs ? 2 : 4),
        behavior: "smooth",
      });
    }
  };

  const prevSlide = () => {
    if (trackRef.current) {
      trackRef.current.scrollBy({
        left: -trackRef.current.clientWidth / (detectOs ? 2 : 4),
        behavior: "smooth",
      });
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: nextSlide,
    onSwipedRight: prevSlide,
    trackMouse: true,
  });

  return (
    <div
      {...handlers}
      className={`${c["carousel-container"]} ${!detectOs ? c["carousel-container-mobile"] : ""}`}
    >
      <div
        className={`${c["carousel-fade"]} ${c["left"]}`}
        style={{ opacity: scrollPos > 0 ? 1 : 0 }}
      />

      <div
        className={`${c["carousel-fade"]} ${c["right"]}`}
        style={{ opacity: scrollPos < maxScroll ? 1 : 0 }}
      />

      {detectOs && (
        <button
          className={`${c["arrow-button"]} ${c["left-arrow"]}`}
          onClick={prevSlide}
          disabled={scrollPos <= 0}
        >
          <MdChevronLeft size={24} />
        </button>
      )}

      <div className={c["carousel-wrapper"]}>
        <div ref={trackRef} className={c["carousel-track"]} onScroll={handleScroll}>
          {myOffers.map((offer, i) => (
            <motion.div
              key={i}
              className={`${c["carousel-item"]} ${!detectOs ? c["carousel-item-mobile"] : ""}`}
            >
              <MyOfferCardItem
                onModalOpen={() => openModal(offer, "reward")}
                offer={offer}
                currencyName={configuration!.currency_name!}
                isInCarousel={true}
              />
            </motion.div>
          ))}
        </div>
      </div>

      {detectOs && (
        <button
          className={`${c["arrow-button"]} ${c["right-arrow"]}`}
          onClick={nextSlide}
          disabled={scrollPos >= maxScroll}
        >
          <MdChevronRight size={24} />
        </button>
      )}
    </div>
  );
};

export default OfferCarousel;
