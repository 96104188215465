import { useEffect, useMemo, useRef, useState } from "react";
import { useErrorHandler } from "contexts/errorContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { getLink } from "api/offers";
import { Goal } from "interfaces/Goal";
import { OfferStatuses } from "interfaces/Offer";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { TiDeviceDesktop, TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";
import { IoIosArrowRoundDown } from "react-icons/io";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import RewardModalDescriptionTask from "./RewardModalDescriptionTask";
import RewardModalGoalTask from "./RewardModalGoalTask";
import OfferInfoBar from "components/offers/OfferModal/OfferInfoBar";
import blueArrow from "../../../assets/images/qr-blue-arrow.png";
import * as c from "./RewardModalContent.module.css";

interface RewardModalContentProps {
  modalContent: {
    attemptedAt: string;
    standardGoals?: Goal[];
    totalStandardGoalRewardIac?: number;
    purchaseGoals?: Goal[];
    totalPurchaseGoalRewardIac?: number;
    goals?: Goal[];
    isCompleted?: boolean;
    description: string[];
    platforms: string[];
    operatingSystems?: string[];
    rewardIac: number;
    title: string;
    thumbnailUrl: string;
    heroImageUrl?: string;
    offerId: number;
    offerStatus: OfferStatuses;
  };
  onOpenQrModal: (qrCode: string) => void;
  onScrollTreshold: (value: boolean) => void;
}

type Platform = "Android" | "iOS" | "Windows";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid color="#A6D864" />,
  iOS: <TiVendorApple />,
  Windows: <TiDeviceDesktop size={19} color="#00a5ed" />,
};

const RewardModalContent = ({
  modalContent,
  onOpenQrModal,
  onScrollTreshold,
}: RewardModalContentProps) => {
  const handleError = useErrorHandler();
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const {
    attemptedAt,
    standardGoals,
    totalStandardGoalRewardIac,
    purchaseGoals,
    totalPurchaseGoalRewardIac,
    goals,
    isCompleted,
    description,
    platforms,
    operatingSystems,
    rewardIac,
    title,
    thumbnailUrl,
    heroImageUrl,
    offerId,
    offerStatus,
  } = modalContent;
  const configuration = getCachedConfiguration();
  const currency = transformCurrency(configuration!.currency_name!, rewardIac);
  const totalStandardGoalCurrency = transformCurrency(
    configuration!.currency_name!,
    totalStandardGoalRewardIac!,
  );
  const totalPurchaseGoalCurrency = transformCurrency(
    configuration!.currency_name!,
    totalPurchaseGoalRewardIac!,
  );
  const totalGoalsCurrency = transformCurrency(
    configuration!.currency_name!,
    totalStandardGoalRewardIac! + totalPurchaseGoalRewardIac!,
  );

  const [daysRemaining, setDaysRemaining] = useState<number | null>(null);
  const [hoursRemaining, setHoursRemaining] = useState<number | null>(null);
  const [url, setUrl] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [offerNotAvailable, setOfferNotAvailable] = useState(false);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const SCROLL_THRESHOLD = 300;
  const COUNT_DOWN_TIME = 30;

  const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({
    instructions: true,
    standardGoals: true,
    purchaseGoals: true,
  });

  const toggleAccordion = (key: string) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const indexZeroTitle = useMemo(() => {
    if (goals && goals.length > 0) {
      return goals![0].title;
    }
  }, [goals]);

  const fullDescription = description.join(" ");

  const basicRequirementsMock = [
    "Must be a new user",
    "Must be completed within specified time window as detailed above",
    "Cannot be connected to a VPN",
    "You must “Allow” the app/game to track your activity to earn rewards",
    "Acitve internet connection required to complete offers",
  ];

  useEffect(() => {
    const updateViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.body.style.setProperty("--viewportHeight", `${viewportHeight}px`);
    };

    window.addEventListener("resize", updateViewportHeight);
    updateViewportHeight();

    return () => window.removeEventListener("resize", updateViewportHeight);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getLink(offerId, configuration!.hashed_publisher_app_uid!)
      .then((data) => {
        setUrl(data.link);
        setQrCode(data.qrCode);
        setIsLoading(false);
      })
      .catch((error) => {
        if (error.response.data.code === "C1167") {
          setOfferNotAvailable(true);
        } else {
          handleError(error);
        }
      });
  }, []);

  useEffect(() => {
    const calculateTimeRemaining = () => {
      const now = new Date();
      const lastAttemptDate = new Date(attemptedAt);
      const diffInMs = now.getTime() - lastAttemptDate.getTime();

      const totalDurationMs = 7 * 24 * 60 * 60 * 1000;
      const remainingMs = totalDurationMs - diffInMs;

      if (remainingMs > 0) {
        const remainingDays = Math.floor(remainingMs / (1000 * 60 * 60 * 24));
        const remainingHours = Math.floor((remainingMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        if (remainingDays > 0) {
          setDaysRemaining(remainingDays);
          setHoursRemaining(null);
        } else {
          setDaysRemaining(null);
          setHoursRemaining(remainingHours);
        }
      } else {
        setDaysRemaining(0);
        setHoursRemaining(0);
      }
    };

    calculateTimeRemaining();
  }, [attemptedAt]);

  useEffect(() => {
    const modal = modalContentRef.current;
    if (modal) {
      modal.addEventListener("scroll", handleModalScroll);
    }
    return () => {
      if (modal) {
        modal.removeEventListener("scroll", handleModalScroll);
      }
    };
  }, []);

  const getTotalCompletedReward = (goals: Goal[]) => {
    const reward = goals.reduce((total, goal) => {
      if (goal.isCompleted) {
        return total + goal.rewardIac;
      }
      return total;
    }, 0);

    return transformCurrency(configuration!.currency_name!, reward);
  };

  const handleModalScroll = () => {
    const modal = modalContentRef.current;
    if (modal) {
      const scrollPosition = modal.scrollTop;

      if (heroImageUrl) {
        scrollPosition >= BUTTON_THRESHOLD ? onScrollTreshold(true) : onScrollTreshold(false);
      }

      const maxScrollHeight = modal.scrollHeight - modal.clientHeight;
      const thresholdPosition = maxScrollHeight - SCROLL_THRESHOLD;

      if (scrollPosition < thresholdPosition) {
        const canShowButton = standardGoals!.length + purchaseGoals!.length > 3;
        if (canShowButton) {
          setIsButtonVisible(true);
        } else {
          setIsButtonVisible(false);
        }
      } else {
        setIsButtonVisible(false);
      }
    }
  };

  const scrollModalContent = () => {
    const modal = modalContentRef.current;
    if (modal) {
      modal.scrollBy({ top: 400, behavior: "smooth" });
    }
  };

  const getRemainingTime = (COUNT_DOWN_TIME: number, attemptedAt: string) => {
    const endTime = new Date(attemptedAt);
    endTime.setDate(endTime.getDate() + COUNT_DOWN_TIME);
    const now = new Date();
    const remainingTime = endTime - now;

    if (remainingTime <= 0) return 0;

    const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
    const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));

    if (days > 0 || hours > 0) {
      return `${days}d ${hours}hr Remaining to Complete this Offer`;
    } else {
      return `${minutes}min Remaining to Complete this Offer`;
    }
  };

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    if (/android/i.test(userAgent)) return "Android";
    return "";
  };

  const BUTTON_THRESHOLD = detectMobileOS() === "" ? 280 : 180;

  const mobileOS = detectMobileOS();

  const isOfferOpen = offerStatus === OfferStatuses.Open;

  const isWebAvailable =
    operatingSystems?.includes("Windows") || operatingSystems?.includes("MacOS");

  return (
    <div className={c["modal-content"]}>
      <div
        className={`${c["modal-content-container"]} ${detectMobileOS() != "" && c["no-web-style"]}`}
        ref={modalContentRef}
      >
        {heroImageUrl && (
          <div
            className={`${c["hero-image-wrapper"]} ${detectMobileOS() != "" && c["no-web-style"]}`}
          >
            <img src={heroImageUrl} alt="Hero Image" />
          </div>
        )}
        <div className={c["modal-content-task-wrapper"]}>
          <div className={c.header}>
            <img src={thumbnailUrl} alt="Game Icon" className={c.icon} />
            <div className={c["header-text"]}>
              <span className={c["title"]}>{title}</span>
              <div className={c["platform-icons"]}>
                {platforms &&
                  platforms.length > 0 &&
                  platforms.map((platform) => (
                    <span key={platform}>{platformIcons[platform as Platform] || null}</span>
                  ))}
              </div>
              <span className={c["sub-title"]}>Earn up to {currency} with this offer</span>
              <span className={c["earn-up"]}>
                {isCompleted ? currency : getTotalCompletedReward(goals!)} / {currency}
              </span>
            </div>
          </div>

          {!isMobile && qrCode !== "" && isOfferOpen && !offerNotAvailable && (
            <div
              className={`${c["qr-content-container"]} ${
                !isWebAvailable ? c["no-web-style"] : c["web-style"]
              }`}
            >
              {isWebAvailable && (
                <div className={c["get-started-button-wrapper"]}>
                  <a href={url} target="_blank" className={c["sign-up-button"]}>
                    Get started on web
                  </a>
                </div>
              )}
              {isWebAvailable && <span className={c["qr-separator"]}>OR</span>}
              <div className={c["qr-code-container"]}>
                <div className={c["qr-code-text-wrapper"]}>
                  <span>Click here to start on your phone</span>
                </div>
                <div className={c["qr-blue-arrow-wrapper"]}>
                  <img src={blueArrow} alt="Blue Arrow" />
                </div>
                <div className={c["qr-platform-icons"]} onClick={() => onOpenQrModal(qrCode)}>
                  {operatingSystems !== null &&
                    operatingSystems?.length !== 0 &&
                    operatingSystems
                      ?.filter((platform) => platform === "Android" || platform === "iOS")
                      .map((platform) => (
                        <span key={platform}>{platformIcons[platform as Platform] || null}</span>
                      ))}
                </div>
              </div>
            </div>
          )}
          {description && description.length && (
            <div className={c["description-wrapper"]}>
              <span>{fullDescription}</span>
            </div>
          )}
          {/* Standard Goals Accordion */}
          {standardGoals && standardGoals.length > 0 && (
            <div
              className={`${c.accordion} ${c["standard-goal-accordion"]} ${
                openAccordions.standardGoals ? c["open-accordion"] : ""
              } ${purchaseGoals?.length === 0 && c["no-purchase-accordion"]}`}
            >
              <button
                className={c["standard-goal-button"]}
                onClick={() => toggleAccordion("standardGoals")}
              >
                {purchaseGoals && purchaseGoals.length === 0 ? "Goals" : "Standard Goals"}{" "}
                <span>{totalStandardGoalCurrency}</span>
                {openAccordions.standardGoals ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>
              {openAccordions.standardGoals && (
                <div className={c["accordion-content"]}>
                  {standardGoals.map((goal, index) => (
                    <>
                      <RewardModalGoalTask
                        key={goal.id}
                        goal={goal}
                        currencyName={configuration!.currency_name!}
                        index={index}
                        daysRemaining={daysRemaining!}
                        hoursRemaining={hoursRemaining!}
                        indexZeroTitle={indexZeroTitle}
                        isPurchaseGoal={false}
                      />
                      {index === 0 && <OfferInfoBar goal={goal} configuration={configuration!} />}
                    </>
                  ))}
                </div>
              )}
            </div>
          )}
          {/* Purchase Goals Accordion */}
          {purchaseGoals && purchaseGoals.length > 0 && (
            <div
              className={`${c.accordion} ${c["purchase-goal-accordion"]} ${
                openAccordions.purchaseGoals ? c["open-accordion"] : ""
              }`}
            >
              <button
                className={c["purchase-goal-button"]}
                onClick={() => toggleAccordion("purchaseGoals")}
              >
                {standardGoals && standardGoals.length === 0 ? "Goals" : "Purchase Goals"}{" "}
                <span>{totalPurchaseGoalCurrency}</span>
                {openAccordions.purchaseGoals ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>
              {openAccordions.purchaseGoals && (
                <div className={c["accordion-content"]}>
                  <div className={c["purchase-info-wrapper"]}>
                    <span className={c["purchase-text-bold"]}>
                      Reach goals faster by making in-app purchases!
                    </span>
                    <span>
                      This highest earners make purchases to complete 3x more goals in the same time
                      period!
                    </span>
                  </div>
                  {purchaseGoals.map((goal, index) => (
                    <RewardModalGoalTask
                      key={goal.id}
                      goal={goal}
                      currencyName={configuration!.currency_name!}
                      index={index}
                      daysRemaining={daysRemaining!}
                      hoursRemaining={hoursRemaining!}
                      indexZeroTitle={indexZeroTitle}
                      isPurchaseGoal={true}
                    />
                  ))}
                </div>
              )}
              <div className={c["total-rewards"]}>
                <span className={c["total-rewards-text-bold"]}>Total rewards</span>
                <span>{totalGoalsCurrency}</span>
              </div>
            </div>
          )}
          <div className={c["basic-requirements"]}>
            <span>Basic Requirements</span>
            <div className={c["basic-requirements-list-wrapper"]}>
              {basicRequirementsMock.map((req, index) => (
                <span key={index}>{`${index + 1}. ${req}`}</span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={c["button-wrapper"]}>
        {isButtonVisible && goals && goals.length > 3 && (
          <div className={c["scroll-down-button-wrapper"]}>
            <button className={c["scroll-down-button"]} onClick={scrollModalContent}>
              <IoIosArrowRoundDown size={"18px"} style={{ marginRight: "2px" }} /> More goals
            </button>
          </div>
        )}
        {isOfferOpen ? (
          <a
            href={url}
            onClick={(e) => {
              if (!isWebAvailable && !isMobile) {
                e.preventDefault();
                onOpenQrModal(qrCode);
              }
            }}
            target="_blank"
            className={`${c["sign-up-button"]} ${!isMobile && isLoading && c["disabled"]}`}
          >
            Get started and earn up to <span className={c.points}>&nbsp;{currency}</span>
          </a>
        ) : (
          <div className={c["countdown-reminder-wrapper"]}>
            {getRemainingTime(COUNT_DOWN_TIME, attemptedAt) <= 0 ? (
              <span className={c["time-is-up"]}>
                This offer was started over <b>30 days ago</b> and is no longer available.
              </span>
            ) : (
              <>
                <p>
                  Continue this offer in <b>{title}</b>
                </p>
                <span className={c["remaining-time"]}>
                  {getRemainingTime(COUNT_DOWN_TIME, attemptedAt)}
                </span>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RewardModalContent;
