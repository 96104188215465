import { TiDeviceDesktop, TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";
import { Offer } from "interfaces/Offer";
import { transformCurrency } from "utils/functions/currencyModUtil";
import * as c from "./HeroOfferCardItem.module.css";

interface HeroOfferCardItemProps {
  onModalOpen: (offer: Offer) => void;
  offer: Offer;
  currencyName: string;
  index: number;
  isMostPopular: boolean;
  detectOs: boolean;
}

type Platform = "Android" | "iOS" | "Windows";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid color="#A6D864" />,
  iOS: <TiVendorApple />,
  Windows: <TiDeviceDesktop size={14} color="#00a5ed" />,
};

function HeroOfferCardItem({
  onModalOpen,
  offer,
  currencyName,
  index,
  isMostPopular,
  detectOs,
}: HeroOfferCardItemProps) {
  const currency = transformCurrency(currencyName, offer.rewardIac);
  const isMultiGoal = offer.goals.length > 1;

  const showHeroImage = () => {
    if (isMostPopular && detectOs) {
      return true;
    }
    if (isMostPopular && !detectOs && index < 1) {
      return true;
    }
  };

  return (
    <div
      className={`${c.card} ${index === 0 && isMostPopular ? c["most-popular-border"] : ""}`}
      onClick={() => onModalOpen(offer)}
    >
      {index === 0 && isMostPopular && (
        <span className={c["most-popular-label"]}>Most popular</span>
      )}
      {showHeroImage() && (
        <div className={c["hero-image-content"]}>
          <img src={offer.heroImageUrl} alt="Logo" />
        </div>
      )}
      <div className={c["card-content"]}>
        <img src={offer.thumbnailUrl} alt="Logo" className={c.logo} />
        <div className={c["text-content"]}>
          <p className={c["title"]}>{offer.title}</p>
          {offer.operatingSystems !== null && offer.operatingSystems.length !== 0 && (
            <div className={c.icons}>
              {offer.operatingSystems.map((platform) => (
                <span key={platform}>{platformIcons[platform as Platform] || null}</span>
              ))}
            </div>
          )}
        </div>
        <div className={c.points}>
          <span className={c["points-text"]}>{isMultiGoal && "Earn up to"}</span>
          <span className={c["points-amount"]}>{currency}</span>
        </div>
      </div>
    </div>
  );
}

export default HeroOfferCardItem;
