export function transformCurrency(
  currencyName: string,
  currencyValue: number,
  omitCurrencyName = false
) {
  if (currencyName === "cents") {
    const dollars = currencyValue / 100;

    if (omitCurrencyName) {
      return dollars;
    }

    return dollars.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
    });
  } else if (currencyName === "euros") {
    const euros = currencyValue / 100;

    if (omitCurrencyName) {
      return euros;
    }

    return (
      euros.toLocaleString("nl-NL", { minimumFractionDigits: 2 }) + "\u20AC"
    );
  } else if (currencyName === "USD") {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(currencyValue);
  } else if (currencyName === "GBP") {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
    }).format(currencyValue);
  } else if (currencyName === "EUR") {
    return new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'EUR',
    }).format(currencyValue);
  } else if (currencyName === "INR") {
    new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).format(currencyValue);
  } else if (currencyName === "SGD") {
    new Intl.NumberFormat('en-SG', {
      style: 'currency',
      currency: 'SGD',
      currencyDisplay: 'code', // will show "SGD 1,234.56"
    }).format(currencyValue);
  } else if (currencyName === "PLN") {
    return new Intl.NumberFormat('pl-PL', {
      style: 'currency',
      currency: 'PLN',
    }).format(currencyValue);
  } else if (currencyName === "SEK") {
    return new Intl.NumberFormat('sv-SE', {
      style: 'currency',
      currency: 'SEK',
    }).format(currencyValue);
  } else if (currencyName === "NOK") {
    return new Intl.NumberFormat('nb-NO', {
      style: 'currency',
      currency: 'NOK',
    }).format(currencyValue);
  } else if (currencyName === "JPY") {
    return new Intl.NumberFormat('ja-JP', {
      style: 'currency',
      currency: 'JPY',
    }).format(currencyValue);
  } else if (currencyName === "BRL") {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(currencyValue);
  } else if (currencyName === "MXN") {
    return new Intl.NumberFormat('es-MX', {
      style: 'currency',
      currency: 'MXN',
    }).format(currencyValue);
  } else if (currencyName === "CHF") {
    return new Intl.NumberFormat('de-CH', {
      style: 'currency',
      currency: 'CHF',
    }).format(currencyValue);
  } else if (currencyName === "NZD") {
    return new Intl.NumberFormat('en-NZ', {
      style: 'currency',
      currency: 'NZD',
    }).format(currencyValue);
  }

  const value = !Number.isInteger(currencyValue)
    ? currencyValue.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : currencyValue.toLocaleString();

  if (omitCurrencyName) {
    return value;
  }

  return value + " " + currencyName;
}
