import * as confetti from "canvas-confetti";
import axiosTokenInterceptor from "interceptors/tokenInterceptor";
import { useErrorHandler } from "contexts/errorContext";
import { CallbackResult } from "interfaces/CallbackResult";
import { NextSurvey } from "interfaces/NextSurvey";
import { SurveyOutcomeEvent } from "interfaces/SurveyOutcomeEvent";
import { SurveyOutcomeTypes } from "interfaces/SurveyOutcomeTypes";
import { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { ProductTypes } from "utils/constants/enums";
import {
  getCachedConfiguration,
  getCurrentSurvey,
  getPublisherFlags,
  setCurrentSurvey,
} from "utils/functions/configurationUtil";
import { isSupplierSurveyMode } from "utils/functions/supplierContextUtil";
import * as systemNotificationUtils from "utils/functions/systemNotificationUtil";
import { CallbackStatuses } from "../pages/Callbacks/BaseCallbackPage";

function useInBrainCallback(callbackStatus: CallbackStatuses) {
  const handleError = useErrorHandler();
  const navigate = useNavigate();

  const configuration = getCachedConfiguration();
  const currencyName = configuration?.currency_name;
  const isDisqualificationDisabled = getPublisherFlags()!.isDisqualificationDisabled;
  const isRewardVisibilityDisabled = getPublisherFlags()!.isRewardVisibilityDisabled;
  const autoSurveyRedirectEnabled = getPublisherFlags()!.autoSurveyRedirectEnabled;
  const isNativeSurveysReturnToAppEnabled = getPublisherFlags()!.isNativeSurveysInAppRoutingEnabled;
  const isSupplierSurvey = isSupplierSurveyMode();


  const [isLoading, setIsLoading] = useState(true);
  const [showAnimatedCta, setShowAnimatedCta] = useState(false);
  const [surveyReward, setSurveyReward] = useState(0);
  const [displayedReward, setDisplayedReward] = useState(0);
  const [clientRedirectLink, setClientRedirectLink] = useState<string | null>(null);
  const [productTypeState, setProductTypeState] = useState<number>(ProductTypes.SurveysWall);

  function handleCallback(url: string, callbackUrl: string | null = null) {
    axiosTokenInterceptor
      .get(url, {
        headers: {
          "x-inBrain-referrer": callbackUrl,
        },
      })
      .then(({ data }: { data: CallbackResult }) => {
        setSurveyReward(data.reward);
        setClientRedirectLink(data.redirectLink);
        const productType = ProductTypes[data.productType as keyof typeof ProductTypes];
        setProductTypeState(productType);

        if (data.nextSurvey) {
          redirectToNextSurvey(
            data.nextSurvey,
            ProductTypes[data.productType as keyof typeof ProductTypes]
          );
          return;
        }

        const outcomeType =
          callbackStatus === CallbackStatuses.Complete
            ? SurveyOutcomeTypes.Complete
            : SurveyOutcomeTypes.Termination;

        const surveyOutcomeEvent: SurveyOutcomeEvent = {
          surveyId: data.surveyId,
          categoryIds: data.surveyCategoryIds,
          placementId: data.placementId,
          userReward: data.reward,
          outcomeType,
        };

        notifySurveyOutcome(surveyOutcomeEvent);

        if (productType == ProductTypes.NativeSurveys) {
          if (isNativeSurveysReturnToAppEnabled && autoSurveyRedirectEnabled) {
              systemNotificationUtils.notifyNativeSurveyClosed();
          } else if (!autoSurveyRedirectEnabled) {
            if (!isDisqualificationCallback()) {
              startConfettiGratification(data.reward);
            }
            setIsLoading(false);
          }

        } else if (productType == ProductTypes.SupplierApi) {
          systemNotificationUtils.notifySurveyClosed();

          if (autoSurveyRedirectEnabled){
            navigateNext(data.redirectLink);
          } else {
            if (!isDisqualificationCallback()) {
              startConfettiGratification(data.reward);
            }
            setIsLoading(false);
          }
        } else {
          systemNotificationUtils.notifySurveyClosed();
          if (!isDisqualificationCallback()) {
            startConfettiGratification(data.reward);
          }
          setIsLoading(false);
        }
      })
      .catch((error) => {
        handleError(error);//TODO Handle the cases where "Survey Not Available" 404??
      });
  }

  function isDisqualificationCallback() {
    return callbackStatus === CallbackStatuses.Disqualification;
  }

  function navigateNext(redirectLink?: string) {
    //handles iFrame case
    if (configuration?.frame_origin) {
      window.opener.postMessage("return-to-surveys", window.location.origin);
      window.close();
      return;
    }

    //handle supplier survey case
    if (isSupplierSurvey) {
      if (clientRedirectLink) {
        window.location.replace(clientRedirectLink);
        return;
      }

      if (redirectLink) {
        window.location.replace(redirectLink);
        return;
      }

      navigate({pathname: "/error"});

      return;
    }

    if (productTypeState == ProductTypes.NativeSurveys) {
      if (isNativeSurveysReturnToAppEnabled) {
        systemNotificationUtils.notifyNativeSurveyClosed();
      }
    }

    //otherwise back to Surveys Wall
    navigate({
      pathname: "/surveys",
      search: createSearchParams({
        reward: surveyReward ? surveyReward.toString() : "",
      }).toString(),
    });
  }

  function redirectToNextSurvey(
    { externalId, virtualId, surveyProviderId, surveyEntryLink }: NextSurvey,
    productType: ProductTypes
  ) {
    if (!surveyEntryLink) {
      throw new Error("Invalid next survey entry link");
    }

    // Search id only matters for state-based surveys like Engage during redirects. Since Engage surveys are not selected for redirects, it's safe to use the search id of the entry survey for next/redirect surveys as part of the same session.
    // If we start using Engage surveys for redirects at some point, we need to make sure new search id is returned as part of the "next survey" response.
    const { searchId } = getCurrentSurvey() || {};

    setCurrentSurvey(externalId, productType, surveyProviderId, virtualId, searchId);

    window.location.href = surveyEntryLink;
  }

  function startConfettiGratification(surveyReward: number) {
    if (surveyReward <= 0) return;

    confetti.create()({
      shapes: ["square"],
      particleCount: 100,
      spread: 90,
      origin: {
        y: 1,
        x: 0.5,
      },
    });

    const rewardStep = Math.ceil(surveyReward / 50);
    let timerStep = 0;
    const timer = setInterval(() => {
      timerStep = timerStep + 20;
      setDisplayedReward((current) => {
        if (timerStep >= 1000 || current + rewardStep >= surveyReward) {
          clearInterval(timer);
          return surveyReward;
        }

        return current + rewardStep;
      });
    }, 20);

    setTimeout(() => {
      setShowAnimatedCta(true);
    }, 1000);
  }

  function notifySurveyOutcome(surveyOutcomeEvent: SurveyOutcomeEvent) {
    systemNotificationUtils.notifySurveyOutcome(surveyOutcomeEvent);
  }

  return {
    isLoading,
    reward: surveyReward,
    displayedReward,
    currencyName,
    isRewardVisibilityDisabled,
    isDisqualificationDisabled,
    showAnimatedCta,
    handleCallback,
    navigateNext
  };
}

export default useInBrainCallback;
