import { Tag } from "./Tag";
import { OfferCreative } from "./OfferCreative";
import { Goal } from "./Goal";

export interface Offer {
  id: number;
  title: string;
  previewUrl: string;
  rewardIac: number;
  popularityScore: number;
  thumbnailUrl: string;
  heroImageUrl?: string;
  earningsPerClick: number;
  description: string[];
  instructions?: string[];
  platforms: string[];
  operatingSystems: string[];
  tags: Tag[];
  offerCreatives: OfferCreative[];
  createdAt: Date | null;
  attemptedAt: string;
  goals: Goal[];
  totalStandardGoalRewardIac?: number;
  standardGoals?: Goal[];
  totalPurchaseGoalRewardIac?: number;
  purchaseGoals?: Goal[];
  isCompleted?: boolean;
  status: OfferStatuses;
  isFeatured: boolean;
}

export enum OfferStatuses {
  Open = "Open",
  Closed = "Closed",
}
