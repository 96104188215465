import * as c from "./Testing.module.css";
import { transformCurrency } from "utils/functions/currencyModUtil";

function Testing() {
  const amount = 1098.37;
  const GBP = transformCurrency("GBP", amount);
  const EUR = transformCurrency("EUR", amount);
  const INR = transformCurrency("INR", amount);
  const SGD = transformCurrency("SGD", amount);
  const PLN = transformCurrency("PLN", amount);
  const SEK = transformCurrency("SEK", amount);
  const NOK = transformCurrency("NOK", amount);
  const JPY = transformCurrency("JPY", amount);
  const BRL = transformCurrency("BRL", amount);
  const MXN = transformCurrency("MXN", amount);
  const CHF = transformCurrency("CHF", amount);
  const Points = transformCurrency("Points", amount);


  return (
    <>
      <p>{GBP}</p>
      <p>{EUR}</p>
      <p>{INR}</p>
      <p>{SGD}</p>
      <p>{PLN}</p>
      <p>{SEK}</p>
      <p>{NOK}</p>
      <p>{JPY}</p>
      <p>{BRL}</p>
      <p>{MXN}</p>
      <p>{CHF}</p>
      <p>{Points}</p>
    </>
  );
}

export default Testing;
