import { useEffect, useRef, useState } from "react";
import { useErrorHandler } from "contexts/errorContext";
import { useBreakpoint } from "hooks/useBreakpoint";
import { postAttempt, getLink } from "api/offers";
import { Goal } from "interfaces/Goal";
import { getCachedConfiguration } from "utils/functions/configurationUtil";
import { transformCurrency } from "utils/functions/currencyModUtil";
import OfferModalGoal from "./OfferModalGoal";
import OfferInfoBar from "./OfferInfoBar";
import { TiDeviceDesktop, TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";
import { IoIosArrowRoundDown } from "react-icons/io";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdInfoOutline } from "react-icons/md";

import * as c from "./HeroOfferModalContent.module.css";

interface OfferModalContentProps {
  modalContent: {
    standardGoals?: Goal[];
    totalStandardGoalRewardIac?: number;
    purchaseGoals?: Goal[];
    totalPurchaseGoalRewardIac?: number;
    instructions?: string[];
    description: string[];
    platforms: string[];
    operatingSystems?: string[];
    rewardIac: number;
    title: string;
    thumbnailUrl: string;
    heroImageUrl?: string;
    offerId: number;
    isFeatured: boolean;
  };
  onOpenQrModal: (qrCode: string) => void;
  onOfferAttempt?: () => void;
  onScrollTreshold: (value: boolean) => void;
}

type Platform = "Android" | "iOS" | "Windows";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid color="#A6D864" />,
  iOS: <TiVendorApple />,
  Windows: <TiDeviceDesktop size={19} color="#00a5ed" />,
};

const HeroOfferModalContent = ({
  modalContent,
  onOpenQrModal,
  onOfferAttempt,
  onScrollTreshold,
}: OfferModalContentProps) => {
  const handleError = useErrorHandler();
  const { isMobile, isTablet, isDesktop } = useBreakpoint();
  const {
    standardGoals,
    totalStandardGoalRewardIac,
    purchaseGoals,
    totalPurchaseGoalRewardIac,
    instructions,
    description,
    platforms,
    operatingSystems,
    rewardIac,
    title,
    thumbnailUrl,
    heroImageUrl,
    offerId,
  } = modalContent;
  const configuration = getCachedConfiguration();
  const currency = transformCurrency(configuration!.currency_name!, rewardIac);
  const totalStandardGoalCurrency = transformCurrency(
    configuration!.currency_name!,
    totalStandardGoalRewardIac!,
  );
  const totalPurchaseGoalCurrency = transformCurrency(
    configuration!.currency_name!,
    totalPurchaseGoalRewardIac!,
  );
  const totalGoalsCurrency = transformCurrency(
    configuration!.currency_name!,
    totalStandardGoalRewardIac! + totalPurchaseGoalRewardIac!,
  );
  const [url, setUrl] = useState("");
  const [qrCode, setQrCode] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const modalContentRef = useRef<HTMLDivElement>(null);
  const SCROLL_THRESHOLD = 300;

  const [openAccordions, setOpenAccordions] = useState<{ [key: string]: boolean }>({
    instructions: true,
    standardGoals: true,
    purchaseGoals: true,
  });

  const toggleAccordion = (key: string) => {
    setOpenAccordions((prev) => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  const fullDescription = description.join(" ");

  const basicRequirementsMock = [
    "Must be a new user",
    "Must be completed within specified time window as detailed above",
    "Cannot be connected to a VPN",
    "You must “Allow” the app/game to track your activity to earn rewards",
    "Acitve internet connection required to complete offers",
  ];

  useEffect(() => {
    const updateViewportHeight = () => {
      const viewportHeight = window.innerHeight;
      document.body.style.setProperty("--viewportHeight", `${viewportHeight}px`);
    };

    window.addEventListener("resize", updateViewportHeight);
    updateViewportHeight();

    return () => window.removeEventListener("resize", updateViewportHeight);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getLink(offerId, configuration!.hashed_publisher_app_uid!)
      .then((data) => {
        setUrl(data.link);
        setQrCode(data.qrCode);
        setIsLoading(false);
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  useEffect(() => {
    const modal = modalContentRef.current;

    if (modal) {
      modal.addEventListener("scroll", handleModalScroll);
    }
    return () => {
      if (modal) {
        modal.removeEventListener("scroll", handleModalScroll);
      }
    };
  }, []);

  const handleSendAttempt = () => {
    postAttempt(offerId, configuration!.hashed_publisher_app_uid!)
      .then(() => {
        onOfferAttempt?.();
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const handleModalScroll = () => {
    const modal = modalContentRef.current;
    if (modal) {
      const scrollPosition = modal.scrollTop;

      if (heroImageUrl) {
        scrollPosition >= BUTTON_THRESHOLD ? onScrollTreshold(true) : onScrollTreshold(false);
      }

      const maxScrollHeight = modal.scrollHeight - modal.clientHeight;
      const thresholdPosition = maxScrollHeight - SCROLL_THRESHOLD;

      if (scrollPosition < thresholdPosition) {
        const canShowButton = standardGoals!.length + purchaseGoals!.length > 3;
        if (canShowButton) {
          setIsButtonVisible(true);
        } else {
          setIsButtonVisible(false);
        }
      } else {
        setIsButtonVisible(false);
      }
    }
  };

  const scrollModalContent = () => {
    const modal = modalContentRef.current;
    if (modal) {
      modal.scrollBy({ top: 400, behavior: "smooth" });
    }
  };

  const detectMobileOS = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return "iOS";
    if (/android/i.test(userAgent)) return "Android";
    return "";
  };
  const BUTTON_THRESHOLD = detectMobileOS() === "" ? 280 : 180;

  const mobileOS = detectMobileOS();

  const isWebAvailable =
    operatingSystems?.includes("Windows") || operatingSystems?.includes("MacOS");

  return (
    <div className={c["modal-content"]}>
      <div
        className={`${c["modal-content-container"]} ${detectMobileOS() != "" && c["no-web-style"]}`}
        ref={modalContentRef}
      >
        {heroImageUrl && (
          <div
            className={`${c["hero-image-wrapper"]} ${detectMobileOS() != "" && c["no-web-style"]}`}
          >
            <img src={heroImageUrl} alt="Hero Image" />
          </div>
        )}
        <div className={c["modal-content-task-wrapper"]}>
          <div className={c.header}>
            <div className={c["header-left"]}>
              <img src={thumbnailUrl} alt="Game Icon" className={c.icon} />
              <div className={c["header-text"]}>
                <span className={c["title"]}>{title}</span>
                <span className={c["sub-title"]}>Earn up to {currency} with this offer</span>
                <div className={c["platform-icons"]}>
                  {operatingSystems !== null &&
                    operatingSystems?.length !== 0 &&
                    operatingSystems?.map((platform) => (
                      <span key={platform}>{platformIcons[platform as Platform] || null}</span>
                    ))}
                </div>
              </div>
            </div>
            <div className={c["header-right"]}>
              {mobileOS === "" && (
                <span className={c["first-time-user"]}>
                  <MdInfoOutline size={"17px"} />
                  Available to first time users only
                </span>
              )}
            </div>
          </div>
          <div className={c["description-wrapper"]}>
            <span>{fullDescription}</span>
          </div>
          {mobileOS !== "" && (
            <span
              className={`${c["first-time-user-mobile"]} ${
                instructions?.length !== 0 && c["no-margin"]
              }`}
            >
              <MdInfoOutline size={"17px"} />
              Available to first time users only
            </span>
          )}

          {/* Instructions Accordion */}
          {instructions && instructions.length > 0 && (
            <div className={`${c.accordion} ${c["instruction-accordion"]} `}>
              <button
                className={c["instruction-button"]}
                onClick={() => toggleAccordion("instructions")}
              >
                <span>Instructions</span>{" "}
                {openAccordions.instructions ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>
              {openAccordions.instructions && (
                <div className={c["accordion-content"]}>
                  <div className={c["instructions-list-wrapper"]}>
                    {instructions.map((instruction, index) => (
                      <span key={index}>{`${index + 1}. ${instruction}`}</span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Standard Goals Accordion */}
          {standardGoals && standardGoals.length > 0 && (
            <div
              className={`${c.accordion} ${c["standard-goal-accordion"]} ${
                openAccordions.standardGoals ? c["open-accordion"] : ""
              } ${purchaseGoals?.length === 0 && c["no-purchase-accordion"]}`}
            >
              <button
                className={c["standard-goal-button"]}
                onClick={() => toggleAccordion("standardGoals")}
              >
                {purchaseGoals && purchaseGoals.length === 0 ? "Goals" : "Standard Goals"}{" "}
                <span>{totalStandardGoalCurrency}</span>
                {openAccordions.standardGoals ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>
              {openAccordions.standardGoals && (
                <div className={c["accordion-content"]}>
                  {standardGoals.map((goal, index) => (
                    <>
                      <OfferModalGoal
                        key={goal.id}
                        goal={goal}
                        currencyName={configuration!.currency_name!}
                        index={index}
                        isPurchaseGoal={false}
                      />
                      {index === 0 && <OfferInfoBar goal={goal} configuration={configuration!} />}
                    </>
                  ))}
                </div>
              )}
            </div>
          )}

          {/* Purchase Goals Accordion */}
          {purchaseGoals && purchaseGoals.length > 0 && (
            <div
              className={`${c.accordion} ${c["purchase-goal-accordion"]} ${
                openAccordions.purchaseGoals ? c["open-accordion"] : ""
              }`}
            >
              <button
                className={c["purchase-goal-button"]}
                onClick={() => toggleAccordion("purchaseGoals")}
              >
                {standardGoals && standardGoals.length === 0 ? "Goals" : "Purchase Goals"}{" "}
                <span>{totalPurchaseGoalCurrency}</span>
                {openAccordions.purchaseGoals ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </button>
              {openAccordions.purchaseGoals && (
                <div className={c["accordion-content"]}>
                  <div className={c["purchase-info-wrapper"]}>
                    <span className={c["purchase-text-bold"]}>
                      Reach goals faster by making in-app purchases!
                    </span>
                    <span>
                      This highest earners make purchases to complete 3x more goals in the same time
                      period!
                    </span>
                  </div>
                  {purchaseGoals.map((goal, index) => (
                    <OfferModalGoal
                      key={goal.id}
                      goal={goal}
                      currencyName={configuration!.currency_name!}
                      index={index}
                      isPurchaseGoal={true}
                    />
                  ))}
                </div>
              )}
              <div className={c["total-rewards"]}>
                <span className={c["total-rewards-text-bold"]}>Total rewards</span>
                <span>{totalGoalsCurrency}</span>
              </div>
            </div>
          )}
          <div className={c["basic-requirements"]}>
            <span>Basic Requirements</span>
            <div className={c["basic-requirements-list-wrapper"]}>
              {basicRequirementsMock.map((req, index) => (
                <span key={index}>{`${index + 1}. ${req}`}</span>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className={c["button-wrapper"]}>
        {isButtonVisible && (
          <div className={c["scroll-down-button-wrapper"]}>
            <button className={c["scroll-down-button"]} onClick={scrollModalContent}>
              <IoIosArrowRoundDown size={"18px"} style={{ marginRight: "2px" }} /> More goals
            </button>
          </div>
        )}

        {!isWebAvailable && !isMobile && (
          <a
            onClick={(e) => {
              e.preventDefault();
              onOpenQrModal(qrCode);
              handleSendAttempt();
            }}
            className={`${c["sign-up-button"]} ${!isMobile && isLoading && c["disabled"]}`}
          >
            Get started and earn up to <span className={c.points}>&nbsp;{currency}</span>
          </a>
        )}

        {(isMobile || isWebAvailable) && (
          <a
            href={url}
            onClick={(e) => {
              handleSendAttempt();
            }}
            target="_blank"
            className={`${c["sign-up-button"]} ${!isMobile && isLoading && c["disabled"]}`}
          >
            Get started and earn up to <span className={c.points}>&nbsp;{currency}</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default HeroOfferModalContent;
