import { Goal } from "interfaces/Goal";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { MdChangeCircle } from "react-icons/md";
import * as c from "./OfferModalGoal.module.css";

interface OfferModalGoalProps {
  goal: Goal;
  currencyName: string;
  index: number;
  isPurchaseGoal: boolean;
}

const OfferModalGoal = ({ goal, currencyName, index, isPurchaseGoal }: OfferModalGoalProps) => {
  const { id, title, description, attributionWindowDays, rewardIac, isCompleted, isPending } = goal;
  const currency = transformCurrency(currencyName, rewardIac);
  const currencyBoost = transformCurrency(currencyName, Math.round(rewardIac / 2));

  return (
    <div className={c.task}>
      <div
        className={`${c["task-content-wrapper"]} ${isCompleted && c["task-content-completed"]} ${
          isPending && c["task-content-pending"]
        }`}
      >
        <div className={c["content-wrapper-top"]}>
          {isCompleted ? (
            <FaCheckCircle color={"#249a63"} size={"20px"} />
          ) : isPending ? (
            <MdChangeCircle color={"#ffb700"} size={"22px"} />
          ) : (
            <FaRegCircle color={"#94a3b8"} size={"20px"} />
          )}
          <div className={c["task-text-wrapper"]}>
            <span>{title}</span>
            {isPending ? (
              <div className={c["task-text-pending"]}>
                <div className={c["task-text-pending-top"]}>
                  <span className={c["task-currency"]}>{currency}</span>
                  <span className={c["task-label"]}>In-Progress</span>
                </div>
                <div className={c["task-text-pending-bottom"]}>
                  <span>Please allow up to 7 days to credit</span>
                </div>
              </div>
            ) : index === 0 && isPurchaseGoal ? (
              <div className={c["task-text-boost"]}>
                <span className={c["task-currency-line"]}>{currencyBoost}</span>
                <span className={c["task-currency-boost"]}>{currency}</span>
                <span className={c["task-boost-label"]}>2x Boost!</span>
              </div>
            ) : (
              <span className={c["task-currency"]}>{currency}</span>
            )}
          </div>
        </div>
        {attributionWindowDays && (
          <div className={c["days-left-container"]}>
            <span>Complete in {attributionWindowDays} days to qualify</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferModalGoal;
