import { Offer } from "interfaces/Offer";
import { transformCurrency } from "utils/functions/currencyModUtil";
import * as c from "./MyOfferCardItem.module.css";

interface MyOfferCardItemProps {
  onModalOpen: (offer: Offer) => void;
  offer: Offer;
  currencyName: string;
  isInCarousel: boolean;
}

function MyOfferCardItem({ onModalOpen, offer, currencyName, isInCarousel }: MyOfferCardItemProps) {
  const currency = transformCurrency(currencyName, offer.rewardIac);
  const isMultiGoal = offer.goals.length > 1;

  const getModifiedGoals = (offer: Offer) => {
    let foundCompleted = false;

    const modifiedGoals = offer.goals.map((goal, index) => {
      if (index === 0 && !goal.isCompleted) {
        return { ...goal, isPending: true };
      }

      if (foundCompleted && !goal.isCompleted) {
        foundCompleted = false;
        return { ...goal, isPending: true };
      }

      if (goal.isCompleted) {
        foundCompleted = true;
      }

      if (offer.goals.every((g) => g.isCompleted)) {
        return { ...goal, isPending: false };
      }

      return goal;
    });

    return { ...offer, goals: modifiedGoals };
  };

  const modifiedOffer = getModifiedGoals(offer);

  const goalsCompletedCount = modifiedOffer.goals.filter((goal) => goal.isCompleted).length;
  const pendingGoalsCount = modifiedOffer.goals.filter((goal) => goal.isPending).length;

  const totalGoals = modifiedOffer.goals.length;

  const progressPercentage =
    totalGoals === 0
      ? offer.isCompleted
        ? 100
        : 10
      : goalsCompletedCount > 0
      ? (goalsCompletedCount / totalGoals) * 100
      : pendingGoalsCount > 0
      ? 10
      : 0;

  const progressColor =
    (totalGoals === 0 && offer.isCompleted) || goalsCompletedCount > 0
      ? "var(--primary-color)"
      : "var(--secondary-color)";

  const textColor =
    (totalGoals === 0 && offer.isCompleted) || goalsCompletedCount > 0
      ? "black"
      : "var(--secondary-color)";

  const progressText =
    totalGoals === 0 && offer.isCompleted
      ? ""
      : totalGoals === 0
      ? "Complete Offer to Earn"
      : goalsCompletedCount > 0
      ? `${goalsCompletedCount}/${modifiedOffer.goals.length} Goals Complete`
      : pendingGoalsCount === 1
      ? "1 Goal Pending"
      : `${pendingGoalsCount} Goals Pending`;

  const earnedCurrency = modifiedOffer.goals
    .filter((goal) => goal.isCompleted)
    .reduce((sum, goal) => sum + goal.rewardIac, 0);

  const progressCurrencyText =
    totalGoals === 0
      ? offer.isCompleted
        ? `${transformCurrency(currencyName, offer.rewardIac)} Earned`
        : `${transformCurrency(currencyName, 0)} Earned so far`
      : goalsCompletedCount === totalGoals
      ? `${transformCurrency(currencyName, earnedCurrency)} Earned`
      : goalsCompletedCount > 0
      ? `${transformCurrency(currencyName, earnedCurrency)} Earned so far`
      : "";

  return (
    <div
      className={`${c.card} ${isInCarousel ? c["card-in-carousel"] : ""}`}
      onClick={() => onModalOpen(offer)}
    >
      <div className={c["card-content-container"]}>
        <div className={c["card-content-info"]}>
          <img src={offer.thumbnailUrl} alt="Logo" className={c.logo} />
          <div className={c["text-content"]}>
            <p className={c["title"]}>{offer.title}</p>
          </div>
          <div className={c.points}>
            <span className={c["points-text"]}>{isMultiGoal && "Earn up to"}</span>
            <span className={c["points-amount"]}>{currency}</span>
          </div>
        </div>
        <div className={c["card-content-progress"]}>
          <div
            className={`${c["progress-info-wrapper"]} ${
              !progressCurrencyText ? c["only-pending"] : ""
            }`}
          >
            {progressCurrencyText && (
              <span className={c["progress-currency"]} style={{ color: `${textColor}` }}>
                {progressCurrencyText}
              </span>
            )}
            <span className={c["progress-goals-completed"]} style={{ color: `${textColor}` }}>
              {progressText}
            </span>
          </div>
          <div className={c["progress-bar-wrapper"]}>
            <div className={c["progress-bar-background"]}>
              <div
                className={c["progress-bar"]}
                style={{
                  width: `${progressPercentage}%`,
                  backgroundColor: progressColor,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MyOfferCardItem;
