import { useEffect, useState } from "react";
import { FaCheckCircle, FaRegCircle } from "react-icons/fa";
import { MdChangeCircle, MdInfoOutline, MdInfo } from "react-icons/md";
import { Goal } from "interfaces/Goal";
import { transformCurrency } from "utils/functions/currencyModUtil";
import * as c from "./RewardModalGoalTask.module.css";

interface RewardModalGoalTaskProps {
  goal: Goal;
  currencyName: string;
  index: number;
  daysRemaining: number;
  hoursRemaining: number;
  indexZeroTitle: string | undefined;
  isPurchaseGoal: boolean;
}

const RewardModalGoalTask = ({
  goal,
  currencyName,
  index,
  daysRemaining,
  hoursRemaining,
  indexZeroTitle,
  isPurchaseGoal,
}: RewardModalGoalTaskProps) => {
  const { id, title, description, completeByUtc, rewardIac, isCompleted, isPending } = goal;
  const currency = transformCurrency(currencyName, rewardIac);
  const currencyBoost = transformCurrency(currencyName, Math.round(rewardIac / 2));
  const [completedTooltipVisible, setCompletedTooltipVisible] = useState(false);
  const [titleTooltipVisible, setTitleTooltipVisible] = useState(false);
  const [infoTooltipVisible, setInfoTooltipVisible] = useState(false);
  const [remainingTimeInfoOpen, setRemainingTimeInfoOpen] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (completedTooltipVisible) {
      timer = setTimeout(() => {
        setCompletedTooltipVisible(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [completedTooltipVisible]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (titleTooltipVisible) {
      timer = setTimeout(() => {
        setTitleTooltipVisible(false);
      }, 2000);
    }
    return () => clearTimeout(timer);
  }, [titleTooltipVisible]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (infoTooltipVisible) {
      timer = setTimeout(() => {
        setInfoTooltipVisible(false);
      }, 6000);
    }
    return () => clearTimeout(timer);
  }, [infoTooltipVisible]);

  const remainingTimeInfoHandler = () => {
    setRemainingTimeInfoOpen(!remainingTimeInfoOpen);
  };

  const getRemainingTime = (completeByUtc: string): string => {
    const completeBy = new Date(completeByUtc);
    const now = new Date();

    const diffMs = completeBy.getTime() - now.getTime();

    if (diffMs <= 0) return "Expired";

    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    const dayLabel = diffDays === 1 ? "day" : "days";
    const hourLabel = diffHours === 1 ? "hour" : "hours";

    return `${diffDays} ${dayLabel} ${diffHours} ${hourLabel}`;
  };

  return (
    <div className={c.task}>
      <div
        className={`${c["task-content-container"]} ${isCompleted && c["task-content-completed"]} ${
          isPending && c["task-content-pending"]
        }`}
      >
        <div className={c["content-wrapper-top"]}>
          {isCompleted ? (
            <FaCheckCircle color={"#249a63"} size={"20px"} />
          ) : isPending ? (
            <MdChangeCircle color={"#ffb700"} size={"22px"} />
          ) : (
            <FaRegCircle color={"#94a3b8"} size={"20px"} />
          )}
          <div className={c["task-text-wrapper"]}>
            <span>{title}</span>
            {isPending ? (
              <div className={c["task-text-pending"]}>
                <div className={c["task-text-pending-top"]}>
                  <span className={c["task-currency"]}>{currency}</span>
                  <span className={c["task-label"]}>In-Progress</span>
                </div>
                <div className={c["task-text-pending-bottom"]}>
                  <span>Please allow up to 7 days to credit</span>
                </div>
              </div>
            ) : index === 0 && isPurchaseGoal ? (
              <div className={c["task-text-boost"]}>
                <span className={c["task-currency-line"]}>{currencyBoost}</span>
                <span className={c["task-currency-boost"]}>{currency}</span>
                <span className={c["task-boost-label"]}>2x Boost!</span>
              </div>
            ) : (
              <span className={c["task-currency"]}>{currency}</span>
            )}
          </div>
        </div>
        {completeByUtc && (
          <div
            className={`${c["remaining-time-info-container"]} ${
              remainingTimeInfoOpen && c["open"]
            }`}
            onClick={remainingTimeInfoHandler}
          >
            {getRemainingTime(completeByUtc) !== "Expired" ? (
              <div className={c["remaining-time-info"]}>
                {remainingTimeInfoOpen ? (
                  <MdInfo color={"white"} size={"18px"} />
                ) : (
                  <MdInfoOutline color={"white"} size={"18px"} />
                )}
                <span className={c["time-info-date"]}>{getRemainingTime(completeByUtc)}</span>{" "}
                <span className={c["time-info-text"]}> remaining to complete</span>
              </div>
            ) : (
              <div className={c["remaining-time-info-expired"]}>
                <span>
                  You are no longer eligible for this goal, time window to complete expired.
                </span>
              </div>
            )}
            {remainingTimeInfoOpen && getRemainingTime(completeByUtc) !== "Expired" && (
              <div className={c["remaining-time-info-wrapper"]}>
                <span>
                  {`To receive the reward of ${currency} for this goal, you must have this goal, “${title}” fully completed within ${getRemainingTime(
                    completeByUtc,
                  )} from now. No reward will be be given
                if you complete this goal longer than ${getRemainingTime(completeByUtc)} from now.`}
                </span>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default RewardModalGoalTask;
