import { useState } from "react";
import { transformCurrency } from "utils/functions/currencyModUtil";
import { Goal } from "interfaces/Goal";
import { Configuration } from "interfaces/Configuration";
import { MdInfoOutline } from "react-icons/md";
import { FaCheckCircle } from "react-icons/fa";

import * as c from "./OfferInfoBar.module.css";

interface OfferInfoBarProps {
  goal: Goal;
  configuration: Configuration;
}

const OfferInfoBar = ({ goal, configuration }: OfferInfoBarProps) => {
  const [infoBarOpen, setInfoBarOpen] = useState(false);
  const currency = transformCurrency(configuration!.currency_name!, goal.rewardIac);

  const infoBarHandler = () => {
    setInfoBarOpen(!infoBarOpen);
  };

  const basicRequirementsMock = [
    "Must be a new user",
    "Must be completed within specified time window as detailed above",
    "Cannot be connected to a VPN",
    "You must “Allow” the app/game to track your activity to earn rewards",
    "Acitve internet connection required to complete offers",
  ];

  return (
    <div
      className={`${c["install-info-wrapper"]} ${
        goal.isCompleted ? c["install-completed"] : c["install-pending"]
      }`}
    >
      <div
        className={`${c["install-info-header"]} ${
          goal.isCompleted ? c["goal-completed"] : c["goal-pending"]
        } ${infoBarOpen && c["infoBarOpen"]}`}
        onClick={infoBarHandler}
      >
        <div className={c["install-info-left"]}>
          <MdInfoOutline
            color={!goal.isCompleted || goal.isPending ? "#00a5ed" : "#249a63"}
            size={"20px"}
          />{" "}
          {!goal.isCompleted || goal.isPending ? (
            <span>
              Verify install completion before progressing. This may take up to 10 minutes.
            </span>
          ) : (
            <div className={c["install-verified-wrapper"]}>
              <span className={c["install-text-bold"]}>Install Verified!</span>
              <span>You can continue on this offer by completing goals!</span>
            </div>
          )}
        </div>
        <div className={c["install-info-right"]}>
          {(!goal.isCompleted || goal.isPending) && (
            <button
              className={`${c["install-info-right-button"]} ${
                infoBarOpen && c["install-info-right-button-open"]
              }`}
            >
              More info
            </button>
          )}
        </div>
      </div>
      <div className={c["install-info-content"]}>
        {infoBarOpen && (
          <div className={c["install-info"]}>
            {!goal.isCompleted || goal.isPending ? (
              <div className={c["verify-install-wrapper"]}>
                <div className={c["verify-install-text"]}>
                  <span className={c["verify-install-text-bold"]}>
                    Look for the green check mark!
                  </span>
                  <span>
                    Getting a successful install indicates that you will be credited for future
                    rewards.
                  </span>
                </div>
                <div className={c["verify-install-content"]}>
                  <div className={c.task}>
                    <div className={c["task-content-wrapper"]}>
                      {<FaCheckCircle color={"#249a63"} size={"20px"} />}
                      <div className={c["task-text-wrapper"]}>
                        <span>{goal.title}</span>
                        <span className={c["task-currency"]}>{currency}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={c["install-verifed-wrapper"]}>
                <div className={c["install-verified-text"]}>
                  <span className={c["install-verified-text-bold"]}>Well done!</span>
                  <span>
                    You´ve now verified that this partner is rewarding you for goal completes. You
                    can now complete goals and earn with confidence!
                  </span>
                  <br />
                  <span>
                    Just make sure you continue following the Basic Requirements for this offer:{" "}
                  </span>
                </div>
                <div className={c["install-verfied-content"]}>
                  <div className={c["basic-requirements-in-verifed-content"]}>
                    <span>Basic Requirements</span>
                    <div className={c["basic-requirements-list-wrapper-in-verifed-content"]}>
                      {basicRequirementsMock.map((req, index) => (
                        <span key={index}>{`${index + 1}. ${req}`}</span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferInfoBar;
