import { useEffect } from "react";
import Button from "./Button";

import { TiVendorAndroid } from "react-icons/ti";
import { TiVendorApple } from "react-icons/ti";

import * as c from "./QrModal.module.css";

interface OfferModalProps {
  show: boolean;
  onClose: () => void;
  qrCode: string;
  operatingSystems: string[];
}

type Platform = "Android" | "iOS" | "Windows";

const platformIcons: Record<Platform, JSX.Element> = {
  Android: <TiVendorAndroid size={18} color="#A6D864" />,
  iOS: <TiVendorApple size={18} />,
  Windows: <></>,
};

const QrModal = ({ show, onClose, qrCode, operatingSystems }: OfferModalProps) => {
  const getPlatformText = (platforms: string[]) => {
    if (platforms.length === 1) {
      return `${platforms[0]} only`;
    } else if (platforms.includes("Android") && platforms.includes("iOS")) {
      return "iOS and Android";
    }
    return null;
  };

  useEffect(() => {
    if (show) {
      document.documentElement.classList.add(c["no-scroll"]);
    } else {
      document.documentElement.classList.remove(c["no-scroll"]);
    }
  }, [show]);

  if (!show) return null;

  return (
    <div className={c["modal-overlay"]}>
      <div className={c.modal}>
        <span className={c["qr-title"]}>Scan with your phone's camera to get started!</span>
        {operatingSystems !== null && operatingSystems.length !== 0 && (
          <div className={c.icons}>
            {operatingSystems.map((platform) => (
              <span key={platform}>{platformIcons[platform as Platform] || null}</span>
            ))}
            <span className={c.platformText}>{getPlatformText(operatingSystems)}</span>
          </div>
        )}
        <div className={c["qr-code-container"]}>
          <div className={c["qr-code-outer-wrapper"]}>
            <div className={c["qr-code-inner"]}>
              <img src={qrCode} alt="QR Code" />
            </div>
          </div>
        </div>
        <Button text={"Back to Offer"} onClick={onClose} disabled={true} isLoading={false} />
      </div>
    </div>
  );
};

export default QrModal;
